import { ellipsis, scrollbar } from 'style/global.styles';
import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';
import * as buttonStyles from 'modules/app/styles/Button.styles';
import { breakpoints } from 'style/variables';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const root = css`
  position: relative;
`;

export const dropdown = css`
  position: absolute;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  z-index: 10;
  border-radius: 6px;
  width: calc(var(--unit) * 54);
  max-width: calc(var(--unit) * 54);
`;

export const theme = (theme: 'light' | 'dark') => {
  if (theme === 'dark') {
    return css`
      background: hsl(var(--color-grayscale-9));
      color: hsl(var(--color-grayscale-1));
    `;
  }
  return css`
    background: hsl(var(--color-white-11));
  `;
};

export const topLeft = css`
  ${dropdown};
  bottom: 100%;
  left: 0;
`;

export const topRight = css`
  ${dropdown};
  bottom: 100%;
  right: 0;
`;

export const bottomLeft = css`
  ${dropdown};
  top: 100%;
  left: 0;
`;

export const bottomRight = css`
  ${dropdown};
  top: 100%;
  right: 0;
`;

export const arrow = (theme: 'light' | 'dark') => {
  if (theme === 'dark') {
    return css`
      background: hsl(var(--color-grayscale-9));
      position: absolute;
      width: 8px;
      height: 8px;
      right: 12px;
      transform: rotate3d(0, 0, 1, 45deg);
    `;
  }

  return css`
    background: hsl(var(--color-white-11));
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate3d(0, 0, 1, 45deg);
  `;
};

export const top = css`
  top: -4px;
`;

export const bottom = css`
  bottom: -4px;
`;

export const caret = css`
  ${margin.left.med}

  & path {
    fill: hsl(var(--color-text-1));
  }

  width: 12px;
  height: 6px;

  transition: transform 0.2s;
`;

export const caretRotated = css`
  ${caret};
  transform: rotate(180deg);
`;

export const dropdownListButton = css`
  ${buttonStyles.root};
  ${buttonStyles.svgButton};
  padding: 0;
  display: block;
  text-align: left;
  width: 100%;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 3);
  ${font.weight.regular};
  ${font.size.xsmall};

  &:hover {
    background-color: hsl(var(--color-backgroundLight-10));
  }
`;

export const loading = css`
  width: 16px;
  height: 16px;
  ${margin.left.med};

  path {
    stroke: black;
    fill: none;
  }
`;

export const newDropdown = (position?: 'left' | 'right') => {
  const styles = css`
    ${dropdown};
    max-height: 300px;
    top: 0;
    padding: calc(var(--unit) * 2);
    overflow: auto;
    ${scrollbar};

    @media ${breakpoints.smallMax} {
      top: 0;
      left: 0;
    }
  `;

  if (position === 'right') {
    return css`
      ${styles};
      top: 0;
      left: calc(100% + (var(--unit) * 10));
    `;
  }

  return css`
    ${styles};
    top: 0;
    right: calc(100% + (var(--unit) * 10));
  `;
};

export const newItemTitle = css`
  padding: calc(var(--unit) * 2) calc(var(--unit) * 3);
  ${font.size.tiny};
  display: flex;
  flex-wrap: nowrap;

  &:hover {
    cursor: pointer;
    path {
      fill: hsl(var(--color-text-1));
    }
  }

  p {
    flex-grow: 1;
    text-align: center;
    ${ellipsis};
  }
`;

export const newItemButton = css`
  ${dropdownListButton};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    flex-basis: 1;
    ${ellipsis};
  }

  svg {
    transform: rotate(180deg);
  }
`;

export const arrowRight = css`
  order: 2;
  transform: rotate(180deg);
`;

export const newItemButtonActive = css`
  ${newItemButton};
  background-color: hsl(var(--color-backgroundLight-10));
`;

export const textPlaceholder = css`
  ${font.size.micro};
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(var(--unit) * 2) calc(var(--unit) * 3);
  color: hsl(var(--color-grayscale-6));
`;

export const dropdownWrapper = css`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: 300px;
  overflow-x: auto;
  padding: calc(var(--unit) * 2);
  ${scrollbar};
`;

export const search = css`
  padding: ${spacing(8)} 0 0;
  width: 100%;
`;

export const searchInput = css`
  width: 100%;
  padding: ${spacing(8)} ${spacing(20)};
  border: none;
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  outline: none;
  ${font.size.small};
`;
